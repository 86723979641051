import { HttpClient, HttpErrorResponse, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Paginated, TableQueryParams, Timestamp, mapToFilterables, prepareQueryParams } from '@chapo/shared-utils';
import { injectMutation, injectQuery, injectQueryClient, toPromise } from '@ngneat/query';
import { ComponentStore } from '@ngrx/component-store';
import { saveAs } from 'file-saver-es';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { catchError, defer, filter, firstValueFrom, of, switchMap, tap, throwError } from 'rxjs';
import { UpdateDocumentAdminForm, UpdateDocumentClientForm } from '../data-access/document-form.type';
import { IDocument, InvoiceStatus } from '../data-access/document.type';

interface DocumentsState {}

@Injectable({
  providedIn: 'root',
})
export class DocumentsService extends ComponentStore<DocumentsState> {
  private http = inject(HttpClient);
  private queryClient = injectQueryClient();
  private query = injectQuery();
  private mutation = injectMutation();

  constructor() {
    super({});
  }

  getAll(queryParams: TableQueryParams) {
    const endpoint = `/backend/api/documents`;
    const queryString = prepareQueryParams(queryParams, (tableFilters) => this.mapToDocumentFilters(tableFilters));

    return this.query({
      queryKey: ['documents', queryParams] as const,
      queryFn: ({ signal }) => {
        const source = this.http.get<Paginated<IDocument>>(
          queryString.length > 0 ? `${endpoint}?${queryString}` : endpoint,
        );

        return toPromise({ source, signal });
      },
    });
  }

  getById(documentId: IDocument['id']) {
    return this.query({
      queryKey: ['documents', documentId] as const,
      queryFn: ({ signal }) => {
        const source = this.http.get<IDocument>(`/backend/api/documents/${documentId}`);

        return toPromise({ source, signal });
      },
    });
  }

  uploadFiles() {
    return this.mutation({
      mutationFn: ({ files }: { files: NzUploadFile[] }) => {
        const formData = new FormData();

        files.forEach((file) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          formData.append('files[]', file as any);
        });

        const request = new HttpRequest('POST', '/backend/api/documents/uploads', formData, {
          reportProgress: true,
        });

        return this.http.request(request).pipe(
          filter((e) => e instanceof HttpResponse),
          switchMap((response) => {
            if (response instanceof HttpResponse) {
              const body = (response as HttpResponse<{ errors: string[] }>).body ?? { errors: [] };

              return of(body);
            }

            return throwError(() => response);
          }),
        );
      },
      onSuccess: () => {
        this.queryClient.invalidateQueries({ queryKey: ['documents'] });
      },
    });
  }

  updateDocumentByAdmin() {
    return this.mutation({
      mutationFn: ({ model }: { model: UpdateDocumentAdminForm }) => {
        return this.http.put(`/backend/api/documents/${model.id}`, model);
      },
      onSuccess: () => {
        this.queryClient.invalidateQueries({ queryKey: ['documents'] });
      },
    });
  }

  updateDocumentByClient() {
    return this.mutation({
      mutationFn: ({ model }: { model: UpdateDocumentClientForm }) => {
        return this.http.put(`/backend/api/documents/${model.id}`, model);
      },
      onSuccess: () => {
        this.queryClient.invalidateQueries({ queryKey: ['documents'] });
      },
    });
  }

  bulkUpdateInvoiceStatus() {
    return this.mutation({
      mutationFn: ({
        document_ids,
        invoice_status,
        invoice_date,
      }: {
        document_ids: number[];
        invoice_status: InvoiceStatus;
        invoice_date?: Timestamp;
      }) => {
        return this.http.post(`/backend/api/documents/bulk_update`, {
          ids: document_ids,
          invoice_status,
          invoice_date,
        });
      },
      onSuccess: () => {
        this.queryClient.invalidateQueries({ queryKey: ['documents'] });
      },
    });
  }

  downloadLayoutTemplate() {
    return this.mutation({
      mutationFn: () => {
        return this.http.get(`/backend/api/exports/document_layout.xlsx`, { responseType: 'blob' }).pipe(
          tap((response) => {
            saveAs(response, `layout.xlsx`);
          }),
        );
      },
    });
  }

  exportDocuments() {
    return this.mutation({
      mutationFn: ({ documentIds }: { documentIds: number[] }) => {
        const filters = [{ key: 'document_id', value: documentIds }];
        const queryString = prepareQueryParams({ filter: filters }, (tableFilters) =>
          this.mapToDocumentFilters(tableFilters),
        );

        return this.http.get(`/backend/api/exports/documents.xlsx?${queryString}`, { responseType: 'blob' }).pipe(
          tap((response) => {
            saveAs(response, `documents.xlsx`);
          }),
        );
      },
    });
  }

  exportPendingInvoices() {
    return this.mutation({
      mutationFn: ({ documentIds }: { documentIds: number[] }) => {
        const filters = [{ key: 'document_id', value: documentIds }];
        const queryString = prepareQueryParams({ filter: filters }, (tableFilters) =>
          this.mapToDocumentFilters(tableFilters),
        );

        return this.http.get(`/backend/api/exports/pre_invoices.xlsx?${queryString}`, { responseType: 'blob' }).pipe(
          tap((response) => {
            saveAs(response, `pre_invoices.xlsx`);
          }),
        );
      },
      onSuccess: () => {
        // We invalidate the documents here because this export is bulk updating their payment status
        this.queryClient.invalidateQueries({ queryKey: ['documents'] });
      },
    });
  }

  exportPayments() {
    return this.mutation({
      mutationFn: ({ documentIds, template }: { documentIds: number[]; template: string }) => {
        const filters = [{ key: 'document_id', value: documentIds }];
        const queryString = prepareQueryParams({ filter: filters }, (tableFilters) =>
          this.mapToDocumentFilters(tableFilters),
        );

        return this.http
          .get(`/backend/api/exports/payments.xlsx?${queryString}${template ? `&template=${template}` : ''}`, {
            responseType: 'blob',
          })
          .pipe(
            catchError(async (response: HttpErrorResponse) => {
              const error$ = defer(() => {
                return (response.error as Blob).text().then((text) => JSON.parse(text) as { errors: string[] });
              });

              return firstValueFrom(error$);
            }),
            switchMap((response) => {
              if (response instanceof Blob) {
                return of({ data: response, errors: null });
              } else {
                return of({ data: null, errors: response.errors });
              }
            }),
            tap((response) => {
              if (response.data) {
                saveAs(response.data, `payments.xlsx`);
              }
            }),
          );
      },
    });
  }

  private mapToDocumentFilters(queryParamsFilters: NonNullable<TableQueryParams['filter']>): string[] {
    return mapToFilterables(queryParamsFilters, {
      company_id: 'company_ids',
      client_id: 'client_ids',
      week: 'week',
      document_id: 'document_ids',
      item_code: 'item_codes',
      item_description: 'item_descriptions',
      invoice_status: 'invoice_statuses',
      payment_status: 'payment_statuses',
    });
  }
}
